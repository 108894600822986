import React from "react";
import "./Animation.css";

const Animation = ({
  videoSrc,
  videoAlt = "Video",
  autoplay = true,
  loop = true,
  muted = true,
  controls = false,
  theme = "light", // Modalità chiara di default
  backgroundColor = "rgba(32, 32, 32, 1)", // Colore di sfondo di default (semi-trasparente)
}) => {
  return (
    <div
      className={`animation-container enlarged-container ${
        theme === "dark" ? "dark-mode" : "light-mode"
      }`}
      style={{ backgroundColor: backgroundColor }} // Imposta il colore di sfondo personalizzato
    >
      {/* Sezione video */}
      <video
        className="animation-video"
        src={videoSrc}
        alt={videoAlt}
        autoPlay={autoplay}
        loop={loop}
        muted={muted}
        controls={controls}
      >
        Il tuo browser non supporta il tag video.
      </video>
    </div>
  );
};

export default Animation;
