import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useFrame } from "@react-three/fiber"; // Cambiato l'import da 'drei' a 'fiber'
import { MathUtils } from "three"; // Per il damping e la velocità
export default function Model(props) {
  const group = useRef();
  const avvitatoreSuperiore = useRef(); // Riferimento all'oggetto Avvitatore_superiore001
  const avvitatoreInferiore = useRef(); // Riferimento all'oggetto Avvitatore_superiore001

  const { nodes, materials, animations } = useGLTF("/3D/stazione4.gltf");
  const { actions } = useAnimations(animations, group);

  // Damping
  const speed = 2; // Velocità di movimento
  const amplitude = 10; // Ampiezza del movimento su e giù
  const delay = 2; // Tempo di attesa tra il movimento su e giù in secondi

  // Animazione continua del movimento su e giù
  useFrame((state, delta) => {
    // Ogni fotogramma, modifichiamo la posizione dell'avvitatore superiore
    if (avvitatoreSuperiore.current) {
      const time = state.clock.getElapsedTime();
      const yPos = MathUtils.lerp(
        amplitude + 140,
        amplitude + 130,
        Math.sin(time * speed) * 0.5 + 0.5
      ); // Movimento di up e down con damping
      avvitatoreSuperiore.current.position.y = yPos;
    }
    if (avvitatoreInferiore.current) {
      const time = state.clock.getElapsedTime();
      const yPos = MathUtils.lerp(
        amplitude,
        amplitude + 15,
        Math.sin(time * speed) * 0.5 + 0.5
      ); // Movimento di up e down con damping
      avvitatoreInferiore.current.position.y = yPos;
    }
  });

  return (
    <group ref={group} {...props} dispose={null} position={[0, -100, -10]}>
      <group name="Scene">
        <group name="T080506CMP" position={[0, 75.516, -254.006]} scale={49}>
          <mesh
            name="SOLID002"
            castShadow
            receiveShadow
            geometry={nodes.SOLID002.geometry}
            material={materials["Material.001"]}
            position={[0.083, 0.088, 0.068]}
            scale={1.409}
          />
        </group>
        <mesh
          name="Pressa001"
          castShadow
          receiveShadow
          geometry={nodes.Pressa001.geometry}
          material={materials.Attrezzi}
          position={[4.082, 84.841, -250.7]}
          rotation={[1.571, 0, Math.PI / 6]}
          scale={55.646}
        />
        <mesh
          name="PalletTemporaneo001"
          castShadow
          receiveShadow
          geometry={nodes.PalletTemporaneo001.geometry}
          material={materials["Plastica_-_Opaco_(nero).001"]}
          position={[4.082, 59.347, -250.699]}
          rotation={[1.571, 0, Math.PI]}
          scale={55.646}
        />
        <mesh
          name="Mesh_Statica001"
          castShadow
          receiveShadow
          geometry={nodes.Mesh_Statica001.geometry}
          material={materials.M_Server}
          position={[18.133, 51.261, -250.698]}
          rotation={[1.571, 0, 0.626]}
          scale={55.646}
        />
        <mesh
          name="Avvitatore_superiore001"
          ref={avvitatoreSuperiore} // Riferimento per animare l'oggetto
          castShadow
          receiveShadow
          geometry={nodes.Avvitatore_superiore001.geometry}
          material={materials.Attrezzi}
          position={[8.402, 142.521, -250.387]}
          rotation={[3.142, -1.571, 0]}
          scale={55.646}
        />
        <mesh
          name="Avvitatore_inferiore001"
          ref={avvitatoreInferiore} // Riferimento per animare l'oggetto
          castShadow
          receiveShadow
          geometry={nodes.Avvitatore_inferiore001.geometry}
          material={materials.Attrezzi}
          position={[8.679, 13.016, -251.272]}
          rotation={[3.142, 1.571, 0]}
          scale={55.646}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/3D/stazione4.gltf");
