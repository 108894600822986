import React from "react";
import "./ManutenzionePredittiva.css";
import DescriptionPhoto from "../../components/DescriptionPhoto/DescriptionPhoto";
import "../Services/ServicePage.css"

const ManutenzionePredittiva = () => {
  return (
    <div className="service-page">
      <div className="service-content">
        <div className="service-container">
          <div className="service-left">
            <h1 className="service-title">Manutenzione predittiva della tua linea</h1>
            <p className="service-description">
              DESCRIZIONE DELLA MANUTENZIONE PREDITTIVA
            </p>
          </div>
          <div className="service-right" style={{ position: "relative" }}>
            <DescriptionPhoto
              title=""
              subtitle=""
              imageSrc="https://via.placeholder.com/500"
              imageAlt="Seconda immagine"
              imageLeft={true}
              theme="dark"
            />
          </div>
        </div>
        <div className="mp-maps-container">
          <div className="mp-info-container">
            <h1>Manutenzione Classica</h1>
            <div className="concept-map">
              <svg className="connections" xmlns="http://www.w3.org/2000/svg">
                <line x1="50%" y1="15%" x2="50%" y2="35%" />

                <line x1="50%" y1="35%" x2="33%" y2="55%" />
                <line x1="50%" y1="35%" x2="66%" y2="55%" />

                <line x1="33%" y1="55%" x2="50%" y2="75%" />
                <line x1="66%" y1="55%" x2="50%" y2="75%" />
              </svg>

              <div className="node center-horiz" style={{ top: "15%" }}>
                Viene eseguita manutenzione su un macchinario o su un componente
              </div>
              <div className="node center-horiz" style={{ top: "35%" }}>
                La manutenzione è prevista fra 6 mesi
              </div>
              <div className="node coppia-2" style={{ top: "55%" }}>
                Aspetto 6 mesi
              </div>
              <div className="node coppia-1" style={{ top: "55%" }}>
                Un componente si rompe, ho un comportamento anomalo
              </div>
              <div className="node center-horiz" style={{ top: "75%" }}>
                Faccio manutenzione
              </div>

            </div>
          </div>

          <div className="mp-info-container">
            <h1>Manutenzione Predittiva</h1>
            <div className="concept-map">
              <svg className="connections" xmlns="http://www.w3.org/2000/svg">
                <line x1="50%" y1="15%" x2="50%" y2="35%" />
                <line x1="50%" y1="35%" x2="50%" y2="55%" />
                <line x1="50%" y1="55%" x2="50%" y2="75%" />
              </svg>
              <div className="node center-horiz" style={{ top: "15%" }}>
                Viene eseguita manutenzione su un macchinario o un componente
              </div>
              <div className="node center-horiz" style={{ top: "35%" }}>
                La manutenzione è prevista fra 6 mesi
              </div>
              <div className="node center-horiz" style={{ top: "55%" }}>
                Analizzo i dati e riscontro un comportamento anomalo prima che diventi critico
              </div>
              <div className="node center-horiz" style={{ top: "75%" }}>
                Faccio manutenzione
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default ManutenzionePredittiva;
