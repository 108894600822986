import React from "react";
import "./TitlePhotos.css";

const TitlePhotos = ({ title, photos }) => {
  // Verifica se photos non è fornito o ha meno di 4 foto
  if (!photos || photos.length < 4) {
    // Se le foto non sono presenti o sono meno di 4, mostra solo il titolo
    return (
      <div className="title-photos-container">
        <h1 className="title">{title}</h1>
      </div>
    );
  }

  return (
    <div className="title-photos-container">
      <h1 className="title">{title}</h1>
      <div className="photos-grid">
        <div className="row">
          <div className="photo photo-rect-left">
            <img src={photos[0]} alt="" />
          </div>
          <div className="photo photo-rect-right">
            <img src={photos[1]} alt="" />
          </div>
        </div>
        <div className="row">
          <div className="photo photo-rect-right">
            <img src={photos[2]} alt="" />
          </div>
          <div className="photo photo-rect-left">
            <img src={photos[3]} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitlePhotos;
