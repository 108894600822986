const blogData = [
  {
    id: 1,
    imgSrc: "/protocol_digitaltwin.jpg",
    title: "Protocolli e Connessioni per l'Integrazione del Gemello Digitale",
    description:
      "Principali protocolli e le connessioni per l'integrazione in tempo reale dei gemelli digitali, con focus su aspetti critici come la sicurezza e l'affidabilità nel contesto Cyber-Physical.",
    subtitle:
      "I protocolli e le connessioni più utilizzati per integrare i gemelli digitali nei sistemi industriali, garantendo efficienza e sicurezza.",
    profileImage: "/protocol_digitaltwin.jpg",
    blocks: [
      //   {
      //     type: "image",
      //     src: "/protocol_digitaltwin.jpg",
      //     alt: "Protocolli e Connessioni",
      //   },
      //   {
      //     type: "text",
      //     content: "",
      //   },
      //   {
      //     type: "code",
      //     language: "javascript",
      //     content: "console.log('Hello World!');",
      //   },
      //   {
      //     type: "list",
      //     items: [""],
      //   },
    ],
  },
];

export default blogData;
