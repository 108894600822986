import React from "react";
import "./TitleOptions.css";

const TitleOptions = ({
  title,
  leftItems, // Array di oggetti {title, subtitle, description}
  rightTitle,
  rightList, // Array di oggetti {text, icon} per la lista destra
  backgroundColor,
  sub_title,
}) => {
  return (
    <div
      className="title-options-container"
      style={{
        backgroundColor: backgroundColor || "var(--color-background-light)",
      }}
    >
      <h1 className="title-options-title">{title}</h1>
      <div className="title-options-content">
        {/* Colonna sinistra */}
        <div className="title-options-left">
          {leftItems.map((item, index) => (
            <div key={index} className="left-item">
              <h3 className="left-item-title">{item.title}</h3>
              <div className="left-item-details">
                <p className="left-item-subtitle">{item.subtitle}</p>
                <p className="left-item-description">{item.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Colonna destra */}
        <div className="title-options-right">
          <h3 className="right-title">{rightTitle}</h3>
          <ul className="right-list">
            {rightList.map((item, index) => (
              <li key={index} className="right-list-item">
                {item.icon && <span className="list-icon">{item.icon}</span>}
                <span className="list-text">{item.text}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {sub_title && <h6 className="title-options-sub_title">{sub_title}</h6>}
    </div>
  );
};

export default TitleOptions;
