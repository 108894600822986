import React, { useState, useCallback, useEffect } from "react";
import ReactFlow, {
  Background,
  Controls,
  applyNodeChanges,
  applyEdgeChanges,
} from "reactflow";
import "reactflow/dist/style.css";
// import "./RealTimeMonitoring.css";
import "../Services/ServicePage.css"

const RealTimeMonitoring = () => {
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [nodes, setNodes] = useState([
    {
      id: "raw-material-intake",
      data: {
        stato: "Attivo",
        output: "200 kg",
        downtime: "0 min",
        ultimaManutenzione: "2 giorni fa",
      },
      position: { x: 50, y: 100 },
      style: {
        background: "#e3f2fd",
        border: "1px solid #2196f3",
        borderRadius: "5px",
        padding: "10px",
      },
    },
    {
      id: "preprocessing",
      data: {
        stato: "Attenzione",
        temperatura: "85°C (elevata)",
        output: "180 kg",
        downtime: "0 min",
      },
      position: { x: 250, y: 100 },
      style: {
        background: "#f1f8e9",
        border: "1px solid #4caf50",
        borderRadius: "5px",
        padding: "10px",
      },
    },
    {
      id: "assembly-line-1",
      data: {
        stato: "Attivo",
        efficienza: "95%",
        output: "160 pz",
        downtime: "0 min",
      },
      position: { x: 450, y: 50 },
      style: {
        background: "#fff9c4",
        border: "1px solid #ffeb3b",
        borderRadius: "5px",
        padding: "10px",
      },
    },
    {
      id: "assembly-line-2",
      data: {
        stato: "Attivo",
        efficienza: "90%",
        output: "150 pz",
        downtime: "5 min (stamattina)",
      },
      position: { x: 450, y: 150 },
      style: {
        background: "#fff9c4",
        border: "1px solid #ffeb3b",
        borderRadius: "5px",
        padding: "10px",
      },
    },
    {
      id: "quality-check",
      data: {
        stato: "Attivo",
        coda: "20 pz in attesa",
        difettosita: "2%",
        output: "140 pz",
      },
      position: { x: 650, y: 100 },
      style: {
        background: "#e8eaf6",
        border: "1px solid #3f51b5",
        borderRadius: "5px",
        padding: "10px",
      },
    },
    {
      id: "packaging",
      data: {
        stato: "Ritardo",
        backlog: 30,
        output: "100 pz",
        downtime: "0 min",
      },
      position: { x: 850, y: 100 },
      style: {
        background: "#fce4ec",
        border: "1px solid #e91e63",
        borderRadius: "5px",
        padding: "10px",
      },
    },
    {
      id: "dispatch",
      data: {
        stato: "Attivo",
        ordiniCoda: "50 pz",
        output: "120 pz",
        ultimoAggiornamento: "10 min fa",
      },
      position: { x: 1050, y: 100 },
      style: {
        background: "#f3e5f5",
        border: "1px solid #9c27b0",
        borderRadius: "5px",
        padding: "10px",
      },
    },
  ]);

  const [edges, setEdges] = useState([
    {
      id: "e-intake-pre",
      source: "raw-material-intake",
      target: "preprocessing",
      label: "Fornitura",
      animated: true,
      style: { stroke: "#2196f3", strokeWidth: 2 },
    },
    {
      id: "e-pre-assembly1",
      source: "preprocessing",
      target: "assembly-line-1",
      label: "Materiali A",
      animated: true,
      style: { stroke: "#4caf50", strokeWidth: 2 },
    },
    {
      id: "e-pre-assembly2",
      source: "preprocessing",
      target: "assembly-line-2",
      label: "Materiali B",
      animated: true,
      style: { stroke: "#4caf50", strokeWidth: 2 },
    },
    {
      id: "e-assembly1-quality",
      source: "assembly-line-1",
      target: "quality-check",
      label: "Output A",
      animated: true,
      style: { stroke: "#ffeb3b", strokeWidth: 2 },
    },
    {
      id: "e-assembly2-quality",
      source: "assembly-line-2",
      target: "quality-check",
      label: "Output B",
      animated: true,
      style: { stroke: "#ffeb3b", strokeWidth: 2 },
    },
    {
      id: "e-quality-packaging",
      source: "quality-check",
      target: "packaging",
      label: "Prodotti Qualificati",
      animated: true,
      style: { stroke: "#3f51b5", strokeWidth: 2 },
    },
    {
      id: "e-packaging-dispatch",
      source: "packaging",
      target: "dispatch",
      label: "Prodotti Imballati",
      animated: true,
      style: { stroke: "#e91e63", strokeWidth: 2 },
    },
  ]);

  const onNodesChange = useCallback((changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  }, []);

  const onEdgesChange = useCallback((changes) => {
    setEdges((eds) => applyEdgeChanges(changes, eds));
  }, []);

  // Funzione per aggiornare dinamicamente i dati di un nodo
  const updateNodeData = (nodeId, newData) => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === nodeId
          ? { ...node, data: { ...node.data, ...newData } }
          : node
      )
    );
  };

  // UseEffect per la simulazione dinamica
  useEffect(() => {
    // Dopo 5 secondi scatta l'allarme temperatura e manutenzione Linea A
    const timeout1 = setTimeout(() => {
      setAlertMessage(
        "Allarme: Temperatura elevata nel Pre-Processo! Linea A in manutenzione."
      );
      setIsMaintenance(true);

      // Aggiorno il nodo assembly-line-1 per riflettere la manutenzione
      updateNodeData("assembly-line-1", {
        stato: "In manutenzione",
        output: "N/A",
        downtime: "15 min",
      });
    }, 5000);

    // Dopo altri 5 secondi (10 secondi totali), la manutenzione è risolta
    const timeout2 = setTimeout(() => {
      if (isMaintenance) {
        setAlertMessage("Manutenzione completata. Linea A torna attiva.");
        setIsMaintenance(false);

        // Aggiornamento dati assembly-line-1 dopo manutenzione
        updateNodeData("assembly-line-1", {
          stato: "Attivo",
          efficienza: "97%",
          output: "170 pz",
          downtime: "0 min",
        });

        // Normalizzazione temperatura nel Pre-Processo
        updateNodeData("preprocessing", {
          stato: "Attivo",
          temperatura: "70°C (normale)",
          output: "190 kg",
        });

        // Riduzione del backlog in imballaggio
        updateNodeData("packaging", {
          backlog: 20,
          output: "110 pz",
        });
      }
    }, 10000);

    // Dopo altri 5 secondi (15 secondi totali), nascondiamo il messaggio
    const timeout3 = setTimeout(() => {
      setAlertMessage("");
    }, 15000);

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
      clearTimeout(timeout3);
    };
  }, [isMaintenance]);

  // Funzione per renderizzare il contenuto del nodo, in base ai dati dinamici
  const renderNodeContent = (node) => {
    switch (node.id) {
      case "raw-material-intake":
        return (
          <div style={{ textAlign: "center" }}>
            <strong>Ingresso Materie Prime</strong>
            <br />
            Stato:{" "}
            <span
              style={{ color: node.data.stato === "Attivo" ? "green" : "red" }}
            >
              {node.data.stato}
            </span>
            <br />
            Output/h: {node.data.output}
            <br />
            Downtime: {node.data.downtime}
            <br />
            Ultima Manutenzione: {node.data.ultimaManutenzione}
          </div>
        );

      case "preprocessing":
        return (
          <div style={{ textAlign: "center" }}>
            <strong>Pre-Processo</strong>
            <br />
            Stato:{" "}
            <span
              style={{
                color: node.data.stato === "Attenzione" ? "#ff9800" : "green",
              }}
            >
              {node.data.stato}
            </span>
            <br />
            Temperatura: {node.data.temperatura}
            <br />
            Output/h: {node.data.output}
            <br />
            Downtime: {node.data.downtime}
          </div>
        );

      case "assembly-line-1":
        return (
          <div style={{ textAlign: "center" }}>
            <strong>Linea Assemblaggio A</strong>
            <br />
            Stato:{" "}
            <span
              style={{
                color: node.data.stato === "In manutenzione" ? "red" : "green",
              }}
            >
              {node.data.stato}
            </span>
            <br />
            {node.data.efficienza && (
              <>
                Efficienza: {node.data.efficienza}
                <br />
              </>
            )}
            Output/h: {node.data.output}
            <br />
            Downtime: {node.data.downtime}
          </div>
        );

      case "assembly-line-2":
        return (
          <div style={{ textAlign: "center" }}>
            <strong>Linea Assemblaggio B</strong>
            <br />
            Stato: <span style={{ color: "green" }}>{node.data.stato}</span>
            <br />
            Efficienza: {node.data.efficienza}
            <br />
            Output/h: {node.data.output}
            <br />
            Downtime: {node.data.downtime}
          </div>
        );

      case "quality-check":
        return (
          <div style={{ textAlign: "center" }}>
            <strong>Controllo Qualità</strong>
            <br />
            Stato: <span style={{ color: "green" }}>{node.data.stato}</span>
            <br />
            Coda: {node.data.coda}
            <br />
            Tasso Difettosità: {node.data.difettosita}
            <br />
            Output/h: {node.data.output}
          </div>
        );

      case "packaging":
        return (
          <div style={{ textAlign: "center" }}>
            <strong>Imballaggio</strong>
            <br />
            Stato:{" "}
            <span
              style={{
                color: node.data.stato === "Ritardo" ? "#ff9800" : "green",
              }}
            >
              {node.data.stato}
            </span>
            <br />
            Backlog: {node.data.backlog} pz
            <br />
            Output/h: {node.data.output}
            <br />
            Downtime: {node.data.downtime}
          </div>
        );

      case "dispatch":
        return (
          <div style={{ textAlign: "center" }}>
            <strong>Spedizione</strong>
            <br />
            Stato: <span style={{ color: "green" }}>{node.data.stato}</span>
            <br />
            Ordini in coda: {node.data.ordiniCoda}
            <br />
            Output/h: {node.data.output}
            <br />
            Ultimo Aggiornamento: {node.data.ultimoAggiornamento}
          </div>
        );

      default:
        return <div>Nodo Sconosciuto</div>;
    }
  };

  // Mappa i nodi attuali in nodi con label aggiornate dinamicamente
  const displayNodes = nodes.map((node) => ({
    ...node,
    data: {
      ...node.data,
      label: renderNodeContent(node),
    },
  }));

  return (
    <div className="service-page">
      <div className="service-content">
        <div className="service-container">
          <div className="service-left">
            <h1 className="service-title">Monitoraggio in Tempo Reale</h1>
            <p className="service-description">
              Questa mappa interattiva mostra in tempo reale il flusso produttivo
              in un plant aziendale. Osserva come le macchine operano, come varia
              la produttività, e come vengono gestiti imprevisti e guasti. In
              questa simulazione, dopo un picco di temperatura, la Linea A viene
              messa in manutenzione. Poi, con l'intervento di un operatore, la
              manutenzione si conclude con successo, la temperatura si normalizza
              e il backlog si riduce, riflettendo le azioni nel plant.
            </p>
          </div>
          <div className="service-right" style={{ position: "relative" }}>
            {/* Box per i messaggi */}
            {alertMessage && (
              <div
                style={{
                  position: "absolute",
                  top: 20,
                  right: 20,
                  background: "#fff",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  padding: "10px 15px",
                  boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                  zIndex: 10,
                }}
              >
                {alertMessage}
              </div>
            )}

            <ReactFlow
              nodes={displayNodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              fitView
              style={{ width: "100%", height: "100%" }}
            >
              <Background color="#f5f5f5" variant="dots" gap={20} />
              <Controls />
            </ReactFlow>
          </div>
        </div>
        <div calssName="service-container">

        </div>
      </div>
    </div>
  );
};

export default RealTimeMonitoring;
