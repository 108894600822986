import React from "react";
import "./Button.css"; // Add styles for the button

const Button = ({ text, className, href }) => {
  const handleClick = () => {
    if (href) {
      window.location.href = href; // Naviga alla pagina specificata
    }
  };

  return (
    <button className={`btn ${className}`} onClick={handleClick}>
      {text}
    </button>
  );
};

export default Button;
