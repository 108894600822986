import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"; // Importa Navigate
import Navbar from "./components/Navbar/Navbar";
import Blog from "./pages/Blog/Blog";
import BlogDetail from "./pages/Blog/BlogDetail";
import HomePage from "./pages/HomePage/HomePage";
import AboutPage from "./pages/AboutPage/AboutPage.jsx";
import RealTimeMonitoring from "./pages/RealTimeMonitoring/RealTimeMonitoring";
import ContactPage from "./pages/ContactPage/ContactPage";
import Footer from "./components/Footer/Footer";
import Lavoraconnoi from "./pages/Lavoraconnoi/Lavoraconnoi.jsx";
import CasiStudio from "./pages/CasiStudioPage/CasiStudioPage";
import CaseDetailsPage from "./pages/CasiStudioPage/CaseDetailsPage";
import ManutenzionePredittiva from "./pages/ManutenzionePredittiva/ManutenzionePredittiva";
import VirtualCommissioning from "./pages/VirtualCommissioning/VirtualCommissioning.jsx";
import SimulationEnviroment from "./pages/SimulationEnviroment/SimulationEnviroment.jsx";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="/chi-siamo"
          element={
            <>
              <Navbar />
              <AboutPage />
              <Footer />
            </>
          }
        />
        <Route
          path="/blog"
          exact
          element={
            <>
              <Navbar />
              <Blog />
              <Footer />
            </>
          }
        />
        <Route
          path="/blog/:id"
          element={
            <>
              <Navbar />
              <BlogDetail />
              <Footer />
            </>
          }
        />
        <Route
          path="/lavora-con-noi"
          element={
            <>
              <Navbar />
              <Lavoraconnoi />
              <Footer />
            </>
          }
        />
        <Route
          path="/contatti"
          element={
            <>
              <Navbar />
              <ContactPage />
              <Footer />
            </>
          }
        />
        <Route
          path="/real-time-monitoring"
          element={
            <>
              <Navbar />
              <RealTimeMonitoring />
              <Footer />
            </>
          }
        />
        <Route
          path="/virtual-commissioning"
          element={
            <>
              <Navbar />
              <VirtualCommissioning />
              <Footer />
            </>
          }
        />
        <Route
          path="/manutenzione-predittiva"
          element={
            <>
              <Navbar />
              <ManutenzionePredittiva />
              <Footer />
            </>
          }
        />
        <Route
          path="/simulation-environment"
          element={
            <>
              <Navbar />
              <SimulationEnviroment />
              <Footer />
            </>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />{" "}
        <Route
          path="/casi-studio"
          element={
            <>
              <Navbar />
              <CasiStudio />
              <Footer />
            </>
          }
        />
        <Route
          path="/casi-studio/:caseId"
          element={
            <>
              <Navbar />
              <CaseDetailsPage />
              <Footer />
            </>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
