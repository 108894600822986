import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom"; // Importa il hook useLocation
import Button from "../UI/Button/Button";
import "./Navbar.css";
import {
  FaChevronDown,
  FaRegClock,
  FaRobot,
  FaCog,
  FaTools,
} from "react-icons/fa"; // Aggiungi le nuove icone

const Navbar = () => {
  const location = useLocation(); // Ottieni il percorso attuale
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null); // Identifica il dropdown aperto
  const [navbarColor, setNavbarColor] = useState("light");

  const dropdownRef = useRef(null);

  useEffect(() => {
    const darkPages = [
      "/",
      "/chi-siamo",
      "/lavora-con-noi",
      "/virtual-commissioning",
    ]; // Elenco delle pagine con tema scuro
    if (darkPages.includes(location.pathname)) {
      setNavbarColor("dark");
    } else {
      setNavbarColor("light");
    }
  }, [location.pathname]);

  const toggleDropdown = (menuName) => {
    setDropdownOpen((prev) => (prev === menuName ? null : menuName));
  };

  const closeDropdowns = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeDropdowns);
    return () => {
      document.removeEventListener("click", closeDropdowns);
    };
  }, []);

  return (
    <nav className={`navbar navbar-${navbarColor}`}>
      <div className="navbar-container" ref={dropdownRef}>
        <div>
          <a href="/" className="navbar-logo">
            <img src="/logo.png" alt="Logo" />
          </a>
        </div>
        <ul className={`navbar-links ${menuOpen ? "open" : ""}`}>
          <li>
            <a href="/chi-siamo">Chi siamo</a>
          </li>

          {/* <li className="dropdown">
            <button
              className="dropdown-toggle"
              onClick={() => toggleDropdown("about")}
            >
              Chi Siamo{" "}
              <FaChevronDown
                className={`dropdown-icon ${dropdownOpen === "about" ? "open" : ""
                  }`}
              />
            </button>
            {dropdownOpen === "about" && (
              <ul className="dropdown-menu">
                <li>
                  <a href="/chi-siamo">
                    <FaHome /> About
                  </a>
                </li>
                <li>
                  <a href="/lavora-con-noi">
                    <FaUsers /> Carriere
                  </a>
                </li>
              </ul>
            )}
          </li> */}

          {/* Dropdown per "Servizi" */}
          {/* <li className="dropdown">
            <button
              className="dropdown-toggle"
              onClick={() => toggleDropdown("services")}
            >
              Servizi{" "}
              <FaChevronDown
                className={`dropdown-icon ${
                  dropdownOpen === "services" ? "open" : ""
                }`}
              />
            </button>
            {dropdownOpen === "services" && (
              <ul className="dropdown-menu">
                <li>
                  <a href="/real-time-monitoring">
                    <FaRegClock /> Real-Time Monitoring
                  </a>
                </li>
                <li>
                  <a href="/virtual-commissioning">
                    <FaRobot /> Virtual Commissioning
                  </a>
                </li>
                <li>
                  <a href="/simulation-environment">
                    <FaCog /> Ambiente di Simulazione
                  </a>
                </li>
                <li>
                  <a href="/manutenzione-predittiva">
                    <FaTools /> Manutenzione Predittiva
                  </a>
                </li>
              </ul>
            )}
          </li> */}
          <li>
            <a href="/blog">Blog</a>
          </li>

          {/* <li>
            <a href="/contatti">Contatti</a>
          </li> */}
          <li>
            <a href="/casi-studio">Casi Studio</a>
          </li>
          <li>
            <a href="/lavora-con-noi">Lavora con noi</a>
          </li>
        </ul>

        <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>

        {/* Button only visible on larger screens */}
        <Button
          text="Contattaci"
          className="btn-primary navbar-btn"
          href="/contatti"
        />
      </div>
    </nav>
  );
};

export default Navbar;
