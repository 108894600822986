const cases = [
  {
    id: 1,
    imgSrc: "/production-line.png",
    title: "Gemello Digitale di una Linea di Produzione",
    description:
      "Un modello digitale avanzato per una linea di produzione di motori, progettato per monitorare in tempo reale le funzioni operative di ciascun macchinario. Il sistema include la gestione degli allarmi critici e l'implementazione della manutenzione predittiva tramite algoritmi di machine learning applicati ai bracci robotici. La connessione tra hardware proprietario Gemini e il PLC centrale garantisce l'acquisizione continua dei dati, mentre l'accesso remoto consente il controllo in tempo reale, migliorando l'efficienza e riducendo i tempi di fermo.",
    video: "/dt3d.mov",
  },
  {
    id: 5,
    imgSrc: "/warehouse.png",
    title: "Gemello Digitale per la Gestione di un Magazzino",
    description:
      "Un sistema avanzato di visualizzazione in tempo reale per la gestione di un magazzino multi-fila dotato di AGV (Automated Guided Vehicles) per il trasporto degli oggetti. La piattaforma permette di monitorare lo stato operativo del magazzino da remoto, supportando decisioni rapide e strategiche. Una rappresentazione grafica dettagliata del magazzino fornisce consigli sull'allocazione ottimale dei prodotti, migliorando l'efficienza logistica e minimizzando errori di stoccaggio.",
    video: "/warehouse.mp4",
  },
  {
    id: 4,
    imgSrc: "rtm.png",
    title: "Monitoraggio in Tempo Reale di una Linea di Produzione",
    description:
      "Un sistema integrato per la gestione remota di una linea di produzione rappresentata in un modello grafico bidimensionale. La soluzione utilizza hardware proprietario Gemini per il monitoraggio continuo di sensori collegati a ciascun macchinario, rappresentati come nodi in un grafo interconnesso. La dashboard fornisce una panoramica intuitiva del funzionamento, consentendo la gestione di allarmi in tempo reale e il miglioramento del processo decisionale per ridurre inefficienze operative.",
    video: "/dt2d.mov",
  },
  {
    id: 3,
    imgSrc: "saix.png",
    title: "Monitoraggio Avanzato di una Flotta di Veicoli",
    description:
      "Una soluzione completa per l'analisi e la rappresentazione in 3D dello stato frenante e sterzante di una flotta di camion. Il sistema consente di monitorare a distanza i parametri critici, inviando notifiche automatiche in caso di superamento delle soglie di degrado, garantendo così la manutenzione predittiva. Il monitoraggio in tempo reale dell'impianto sterzante e frenante è supportato da una telematic unit avanzata, che assicura connettività costante e un controllo accurato.",
    video: "/saix.mov",
  },
];

export default cases;
