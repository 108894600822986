import React, { useRef, useEffect } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import { OrbitControls, Environment } from "@react-three/drei";
import * as THREE from "three"; // Importa THREE per usare Box3, Vector3, ecc.

// Importa il modello 3D
import Stazione4 from "./stazione4";

const CameraRig = ({ modelRef }) => {
  const { camera } = useThree(); // useThree viene ora usato all'interno di Canvas

  useEffect(() => {
    if (modelRef.current && camera) {
      const boundingBox = new THREE.Box3().setFromObject(modelRef.current);
      const center = new THREE.Vector3();
      boundingBox.getCenter(center); // Ottieni il centro del modello

      // Posiziona la fotocamera per ottenere una vista dall'alto e da lato
      camera.position.set(center.x + 200, center.y + 100, center.z + 200); // Fotocamera posizionata in alto e lateralmente
      camera.lookAt(center); // La fotocamera guarda il centro del modello
    }
  }, [modelRef, camera]);

  return null; // Questo componente non rende nulla
};

const ThreeDModel = () => {
  const modelRef = useRef();

  return (
    <Canvas
      shadows // Abilita ombre per un'illuminazione più realistica
      camera={{ near: 0.1, far: 1000, fov: 50 }}
    >
      {/* Aggiungi il controllo orbitale, ma disabilita l'interazione */}
      <OrbitControls
        enableZoom={false} // Disabilita lo zoom
        enableRotate={false} // Disabilita la rotazione
        enablePan={false} // Disabilita il pan
      />

      {/* Posiziona la fotocamera sopra il modello */}
      <CameraRig modelRef={modelRef} />

      {/* Aggiungi una luce ambientale morbida */}
      <ambientLight intensity={0.5} color="#ffffff" />

      {/* Aggiungi una luce direzionale potente per simulare la luce solare */}
      <directionalLight
        position={[5, 5, 5]} // Posiziona la luce in alto per simulare una sorgente solare
        intensity={1.2}
        color="#ffffff"
        castShadow
        shadow-mapSize-width={2048} // Maggiore qualità delle ombre
        shadow-mapSize-height={2048} // Maggiore qualità delle ombre
        shadow-camera-far={20} // Aumenta la distanza di proiezione delle ombre
      />

      {/* Luce di riempimento soft per evitare ombre dure */}
      <directionalLight
        position={[-5, -5, -5]}
        intensity={0.4}
        color="#ff9e00"
        castShadow
      />

      {/* Aggiungi un ambiente HDR per una luce di sfondo realistica */}
      <Environment preset="sunset" />

      {/* Carica e visualizza il modello 3D */}
      <Stazione4 ref={modelRef} />
    </Canvas>
  );
};

export default ThreeDModel;
