import React from "react";
import { useParams } from "react-router-dom";
import cases from "./cases";
import "./CaseDetailsPage.css";

const CaseDetailsPage = () => {
  const { caseId } = useParams();
  const caseDetails = cases.find((c) => c.id === parseInt(caseId));

  if (!caseDetails) {
    return <div>Il caso studio non è stato trovato.</div>;
  }

  return (
    <div className="case-details-page">
      {/* Video full screen */}
      <div className="video-container">
        <video src={caseDetails.video} autoPlay loop muted></video>
      </div>

      {/* Contenuto dettagliato */}
      <div className="details-container">
        <h1>{caseDetails.title}</h1>
        <p>{caseDetails.description}</p>
        {/* <img src={caseDetails.imgSrc} alt={caseDetails.title} /> */}
      </div>
    </div>
  );
};

export default CaseDetailsPage;
