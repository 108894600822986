// BlogRenderer.js
import React from "react";
import "./BlogRenderer.css"; // Assicurati di avere questo file

const BlockRenderer = ({ block }) => {
  return (
    <div className="block-wrapper">
      {(() => {
        switch (block.type) {
          case "text":
            return <p>{block.content}</p>;
          case "image":
            return (
              <img src={block.src} alt={block.alt} className="article-image" />
            );
          case "code":
            return (
              <pre>
                <code>{block.content}</code>
              </pre>
            );
          case "list":
            return (
              <ul>
                {block.items.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};

export default BlockRenderer;
