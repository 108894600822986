import React from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import "./Blog.css";
import Title from "../../components/Title/Title";
import blogData from "./blogData";

const Blog = () => {
  return (
    <div className="blog-page">
      <div className="blog-main">
        <Title title="Leggi le ultime notizie." />
        <div className="blog-content">
          {blogData.map((post) => (
            <Link
              to={`/blog/${post.id}`}
              key={post.id}
              className="blog-card-link"
            >
              <div
                className="blog-card"
                style={{ backgroundImage: `url(${post.imgSrc})` }}
              >
                <div className="blog-card-content">
                  <h3>{post.title}</h3>
                  <p>{post.description}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;
