import React, { useState } from "react";
import "./ContactPage.css";
import Title from "../../components/Title/Title";
import emailjs from "emailjs-com";
import Options3 from "../../components/3Options/Options3";
const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    company: "",
    project: "",
    message: "",
    privacyPolicy: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.privacyPolicy) {
      alert("Devi accettare la privacy policy.");
      return;
    }

    const templateParams = {
      name: formData.name,
      surname: formData.surname,
      email: formData.email,
      company: formData.company,
      project: formData.project,
      message: formData.message,
    };

    emailjs
      .send(
        "service_4yzlrgk",
        "template_ma3jjvo",
        templateParams,
        "rS-iTvWY5gQyGarxb"
      )
      .then(
        (response) => {
          console.log("Email inviata con successo", response);
          alert("Messaggio inviato con successo!");
          setFormData({
            name: "",
            surname: "",
            email: "",
            company: "",
            project: "",
            message: "",
            privacyPolicy: false,
          });
        },
        (err) => {
          console.error("Errore nell'invio dell'email", err);
          alert("Si è verificato un errore, riprova più tardi.");
        }
      );
  };

  const options = [
    // {
    //   title: "Fissa una videocall",
    //   description: "",
    //   buttonText: "Contattaci",
    // },
    {
      title: "Inviaci una email",
      description: "Scrivi direttamente al nostro team di vendita: alberto.besozzi@geminiautomation.it",
      buttonText: "",
    },
    {
      title: "Entra a far parte del nostro team",
      description: "",
      buttonText: "Carriere",
      link: "/lavora-con-noi"
    },
  ];

  return (
    <div className="contact-page">
      <div className="contact">
        <Title title="Contattaci." />
        <div className="contact-content">
          <h1>Compila il form</h1>
          <form onSubmit={handleSubmit} className="contact-form">
            <div className="form-group">
              <label htmlFor="name">Nome*</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="surname">Cognome*</label>
              <input
                type="text"
                id="surname"
                name="surname"
                value={formData.surname}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="company">Azienda*</label>
              <input
                type="text"
                id="company"
                name="company"
                value={formData.company}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="project">Progetto*</label>
              <input
                type="text"
                id="project"
                name="project"
                value={formData.project}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="message">Messaggio*</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>

            <div className="form-group">
              <label>
                <input
                  type="checkbox"
                  name="privacyPolicy"
                  checked={formData.privacyPolicy}
                  onChange={handleChange}
                  required
                />
                Dichiaro di aver preso visione della{" "}
                <a href="/privacy-policy">privacy policy</a>
              </label>
            </div>

            <button type="submit" className="submit-button">
              Invia Messaggio
            </button>
          </form>
        </div>
        <Options3 title="" options={options} />
      </div>
    </div>
  );
};

export default ContactPage;
