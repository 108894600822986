import React from "react";
import { useParams } from "react-router-dom"; // Per ottenere il parametro ID dalla URL
import "./BlogDetail.css";
import BlockRenderer from "./BlogRenderer";
import blogData from "./blogData"; // Import shared data

const BlogDetail = () => {
  const { id } = useParams(); // Ottieni l'ID dell'articolo dalla URL
  const article = blogData.find((art) => art.id === parseInt(id));

  if (!article) {
    return <div>Articolo non trovato</div>;
  }

  return (
    <div className="blog-detail-page">
      <img
        src={article.profileImage}
        className="profile-image"
        alt={article.title}
      />

      <h1>{article.title}</h1>
      <div className="article-content">
        {article.blocks.map((block, index) => (
          <BlockRenderer key={index} block={block} />
        ))}
      </div>
    </div>
  );
};

export default BlogDetail;
