import React from "react";
import { useNavigate } from "react-router-dom";
import "./CasiStudioPage.css";
import Title from "../../components/Title/Title";
import cases from "./cases";

const CasiStudioPage = () => {
  const navigate = useNavigate();

  const handleCardClick = (caseId) => {
    navigate(`/casi-studio/${caseId}`);
  };

  return (
    <div className="cs-page">
      <div className="cs">
        <Title title="I nostri casi studio." />
        <div className="cs-content">
          {cases.map((caseItem) => (
            <div
              className="cs-card"
              key={caseItem.id}
              style={{ backgroundImage: `url(${caseItem.imgSrc})` }}
              onClick={() => handleCardClick(caseItem.id)}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CasiStudioPage;
