import React from "react";
import "./VirtualCommissioning.css";
import DescriptionPhoto from "../../components/DescriptionPhoto/DescriptionPhoto";
import "../Services/ServicePage.css"

const VirtualCommissioning = () => {
    return (
        <div className="service-page">
            <div className="service-content">
                <div className="service-container">
                    <div className="service-left">
                        <h1 className="service-title">Virtual Commissioning</h1>
                        <p className="service-description">
                            DESCRIZIONE VIRTUAL COMMISSIONING
                        </p>
                    </div>
                    <div className="service-right" style={{ position: "relative" }}>
                        <DescriptionPhoto
                            title=""
                            subtitle=""
                            imageSrc="https://via.placeholder.com/500"
                            imageAlt="Seconda immagine"
                            imageLeft={true}
                            theme="dark"
                        />
                    </div>
                </div>

            </div>

        </div>
    );
};

export default VirtualCommissioning;
